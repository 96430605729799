<template>
  <div>
    <AppHeader />
    <TTView>
      <VRow justify="center">
        <VCol
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <VImg
            :src="require('@/assets/img/surv_another_org.svg')"
            width="100%"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol class="text-center">
          <h1 class="font-weight-medium">
            {{ $t('surveyForAnotherOrganization') }}
          </h1>
          <div class="tt-black--text text--lighten-2 mt-4">
            {{ $t('connectProvider') }}
          </div>
        </VCol>
      </VRow>
    </TTView>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';

export default {
  name: 'SurveyAnotherOrganization',
  components: { AppHeader, TTView },
};
</script>
